<template>
  <!-- 需求发布列表详情 -->
  <div v-if="item && item.id" class="zt-page-content flex flex-v">
    <div class="flex-1" style="overflow: auto">
      <DayangDetail v-if="item.type == 'dayang'" :item="item" @onSuccess="refreshItems"></DayangDetail>
      <DemandDetail v-else :detailInfo="item" :imgUrl="imgUrl" :type="2"></DemandDetail>
    </div>
    <div class="button">
      <el-button @click="republish(item)" v-if="item.state==5" type="text">重新发起</el-button>
      <el-button @click="edit(item,tabIndex)" v-if="item.state==1" type="text">继续编辑</el-button>
      <el-button
        @click="close(item)"
        v-if="[2,3].includes(item.state)"
        size="mini"
        type="primary"
        plain
        round
      >关闭需求</el-button>
      <el-button
        @click="goto('/want/wantFollow/pipeList/'+item.id+'?type='+item.type)"
        v-if="[2,3,4].includes(item.state)"
        size="mini"
        type="primary"
      >查看应答</el-button>
      <!-- <el-button
        @click="del(item)"
        v-if="item.state==1 || item.state==5"
        size="mini"
        type="danger"
        plain
      >删除</el-button> -->
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { IMG_URL_PRE } from "@/config";
import {
  getRequireId,
  add_pubRequire,
  copy_pubRequire,
  dcRequire,
  closeRequire,
  delRequire
} from "@/service/want";
import DayangDetail from "./DayangDetail.vue";
import DemandDetail from "./DemandDetail.vue";
export default {
  components: {
    DayangDetail,
    DemandDetail
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popTitle: "",
      popVisible: false,
      item: {}
    };
  },
  created() {
    this.refreshItems();
  },
  mounted() {},
  methods: {
    goto(link) {
      this.$router.push(link);
    },
    back() {
      this.$router.go(-1);
    },
    refreshItems() {
      getRequireId(this.$route.params.id)
        .then(rst => {
          this.item = rst;
        })
        .catch(err => {
          console.log(err);
        });
    },
    issue(item) {
      let _item = _.clone(item);
      this.$confirm("确认是否发布?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        add_pubRequire(_item)
          .then(rst => {
            this.refreshItems();
            this.$message.success("发布成功");
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      });
    },
    republish(item) {
      this.$confirm("确认是否重新发布该需求?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        copy_pubRequire(item.id)
          .then(rst => {
            this.tabIndex = 0;
            this.queryOpt.where.states = [2];
            this.refreshItems();
            this.$message.success("重新发布成功");
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      });
    },
    edit(item) {
      this.$router.push({
        path: "/want/wantIssue",
        query: { id: item.id, type: item.type }
      });
    },
    dc_require(item) {
      this.$confirm("确认是否达成该需求?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        dcRequire(item.id)
          .then(rst => {
            this.refreshItems();
            this.$message.success("达成成功");
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      });
    },
    close(item) {
      this.$confirm("确认是否关闭?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        closeRequire(item.id)
          .then(rst => {
            this.refreshItems();
            this.$message.success("关闭成功");
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      });
    },
    del(item) {
      this.$confirm("确认是否删除?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delRequire(item.id)
          .then(rst => {
            this.refreshItems();
            this.$message.success("删除成功");
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      });
    }
  }
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.zt-block {
  box-shadow: none;
}
.item {
  padding: 20px 30px;
  font-size: 14px;
  border: 1px solid rgba(230, 230, 230, 1);
}
.item h3 {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
}
.img_group > div {
  width: 120px;
  height: 120px;
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  position: relative;
  left: -5px;
}
.list-item {
  padding: 10px 0;
  background: rgba(250, 250, 255, 1);
}
.list-item > div {
  min-width: 160px;
  min-height: 110px;
  padding: 0 30px;
  border-right: 1px solid #e6e6e6;
}
.list-item > div:last-child {
  border-right: 0;
}
.list-item .list-item-tr > div:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
  word-wrap: break-word;
}
.list-desc {
  padding: 30px;
  border: 1px solid rgba(230, 230, 230, 1);
}
.list-desc > div {
  margin-bottom: 20px;
}
.list-desc > div:last-child {
  margin-bottom: 0px;
}
.list-desc > div > div:first-child {
  font-size: 14px;
  color: #808080;
  line-height: 22px;
  margin-bottom: 12px;
  margin-right: 10px;
}
.button {
  height: 70px;
  padding: 20px 30px;
  background: #fff;
  border: 1px solid rgba(230, 230, 230, 1);
}
.button.active {
  height: 0;
  padding: 0;
}
</style>